import { Dispatch, SetStateAction, useEffect, useState } from "react"
import removeAccents from "remove-accents"
import {
  DeliveryExcelTypes,
  ISheetRow,
  priorityKeys,
} from "@/modules/shared/UploadTemplate/upload.types"
import { v4 as uuidv4 } from "uuid"
import { ServerDeliveryType } from "@/types/deliveryTypes.types"
import { IQuotePayload } from "@/types/quotes.types"
import { UploadFile } from "antd"
import { useUser } from "./useUser"
import {
  isEmptyCell,
  getAddressDetail,
  formatUploadedParcelDetails,
  getSchedule,
  formatUploadedRecipientsFromTemplate,
  getReverseAddress,
  getCleanUploadedRecipient,
  checkLatLonValid,
  convertFiletoJson,
  hasParcelInfo,
} from "@/modules/shared/UploadTemplate/upload_utils"
import { VehicleType } from "@/types/vehicle.types"
import { formatPhoneNumber } from "@/utils/FormatPhoneNumber"
import { EU_DATE_FORMAT_UPLOAD, EU_DATE_TIME_FORMAT_UPLOAD } from "@/utils/datetime"
import { splitFullName } from "@/utils/formatFullName"
import { CountryCode } from "libphonenumber-js"
import { isEmpty } from "lodash"
import { DateTime } from "luxon"
import { useTeams } from "./useTeams"
import { isCodAvailableCountryAtom, locationsSelector } from "@/atoms/userAtom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useTranslation } from "react-i18next"
import { PickupDetailsType } from "@/types/createSingleOrder.types"
import { formatSenderRecipientPayload } from "@/api/quotes"
import { localizationAtom } from "@/atoms/localizationAtom"
import { DestinationPriority, RecipientMetadata, StopType } from "@/types/orders.types"
import { useGeocoding } from "./useGeocoding"
import { useDraftOrders } from "./useDraftOrders"
import { DraftOrder } from "@/types/draftOrder.types"
import { useClients } from "./useClients"
import { ErrorNotification } from "@/UI/Notifications/NotificationTemplate.component"
import { UploadError, uploadSummaryAtom } from "@/atoms/uploadSummaryAtom"

export interface IUseUploadTeamplate {
  file: UploadFile[]
  setFile: any
  processTemplateFile: (
    uploadCallback: (orders: DraftOrder[] | IQuotePayload[]) => Promise<any>
  ) => Promise<IQuotePayload[] | undefined>
  progressPercent: number
  isLoading: boolean
  isDisabled: boolean
  setSelectedPickup: Dispatch<SetStateAction<PickupDetailsType | undefined>>
  selectedPickupId: string | undefined
}

export type AddressDetails = {
  postalCode: string
  lat: number
  lng: number
  isWarning?: boolean
}

export enum UPLOAD_ERRORS {
  CLIENT_NOT_FOUND = "client_not_found",
  RECIPIENT_AND_SENDER_MISSING = "recipient_and_sender_missing",
  RECIPIENT_INCORRECT_ADDRESS = "recipient_incorrect_address",
  RECIPIENT_INCORRECT_COORDINATES = "recipient_incorrect_coordinates",
  SENDER_INCORRECT_LOCATION = "sender_incorrect_location",
}

const isUndefined = (value?: string) => {
  return !value || value === "undefined"
}
export const MAX_UPLOAD_DRAFT_LIMIT = 5000

export const useUploadTemplate = (isPublic: boolean): IUseUploadTeamplate => {
  const { t } = useTranslation()

  const { user } = useUser()
  const { enrichedClients, fetchClients } = useClients()
  const { geocodeByAddress } = useGeocoding()
  const { createDraftOrders } = useDraftOrders()
  const locations = useRecoilValue(locationsSelector)

  const isCodAvailableCountry = useRecoilValue(isCodAvailableCountryAtom)
  const { locale } = useRecoilValue(localizationAtom)

  const [file, setFile] = useState<UploadFile[]>([])
  const setUploadSummary = useSetRecoilState(uploadSummaryAtom)
  const [selectedPickup, setSelectedPickup] = useState<PickupDetailsType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [progressPercent, setProgressPercent] = useState<number>(0)
  const referenceCountry = user?.org?.country || locale

  const { findDriverByName, findTeamByName, findDriverById } = useTeams()

  const addressCache: Record<string, AddressDetails> = {}
  const coordsCache: Record<string, string> = {}

  useEffect(() => {
    setIsDisabled(!file)
  }, [file])

  useEffect(() => {
    if (file) {
      setIsDisabled(isLoading)
    }
  }, [isLoading])

  const handleFileError = (title: string, error: any, durationNull?: boolean) => {
    ErrorNotification(
      title,
      <div className="flex flex-col gap-y-2">
        <p>{error.message}</p>
        <p>{t("errors.draftUpload.reUploadError")}</p>
      </div>,
      undefined,
      durationNull
    )
  }

  const convertSheetDataToQuotePayload = async (order: ISheetRow): Promise<IQuotePayload> => {
    try {
      let {
        recipientName,
        recipientAddress,
        recipientPostalCode,
        buildingNumber,
        recipientCountry,
        recipientLatitude,
        recipientLongitude,
        recipientPhone,
        recipientEmail,
        dropOffNote,
        stopType,

        senderName,
        senderAddress,
        senderPostalCode,
        senderBuildingNumber,
        senderLatitude,
        senderLongitude,
        senderPhone,
        senderEmail,
        senderNote,

        cashOnDeliveryAmount,
        orderReferenceId,
        barcode,
        // productType,
        orderNumber,

        teamName,
        driverName,

        parcelValue,
        parcelWeight,
        parcelLength,
        parcelHeight,
        parcelWidth,
        parcelQuantity,
        parcelDescription,
        parcelDimensions,
        parcelDimensionsV2,

        date,
        scheduledTime,
        arrivalTimeFrom,
        arrivalTimeTo,
        timeAtStop,
        vehicleType,
        priority,
        clientId,
        endLocation,
        endLocationTime,
      } = order
      const orderNo = orderNumber || uuidv4()

      const quote: IQuotePayload = {
        recipients: [],
        deliveryType: ServerDeliveryType.EMPTY,
        ...(!isEmptyCell(vehicleType)
          ? { vehicles: [vehicleType?.toUpperCase()] as VehicleType[] }
          : {}),
        //@ts-ignore
        orderNumber: `${orderNo}`,
      }
      const hasSenderLatLng =
        Number(senderLatitude) &&
        Number(senderLongitude) &&
        checkLatLonValid(Number(senderLatitude), Number(senderLongitude))
      // Pickup handling
      if (selectedPickup) {
        quote.sender = formatSenderRecipientPayload(selectedPickup)
      } else if (senderName || senderAddress || hasSenderLatLng) {
        const formattedSenderAddress = `${senderAddress}${
          senderPostalCode ? " " + senderPostalCode : ""
        }`

        let senderAddressDetails = addressCache[senderAddress]

        if (!senderAddressDetails) {
          if (!senderLatitude && !senderLongitude) {
            senderAddressDetails = await getAddressDetail(
              geocodeByAddress,
              formattedSenderAddress,
              (recipientCountry as CountryCode) || referenceCountry,
              undefined,
              `${recipientPostalCode}`
            )
          } else {
            senderAddressDetails = {
              lat: Number(senderLatitude),
              lng: Number(senderLongitude),
              postalCode: `${senderPostalCode}`,
              isWarning: false,
            }
          }
          addressCache[senderAddress] = senderAddressDetails
        }

        const { postalCode, lat, lng, isWarning } = senderAddressDetails

        const { firstName, lastName } = splitFullName(senderName ? `${senderName}` : "")

        quote.sender = {
          firstName: firstName ? `${firstName}` : "",
          lastName: lastName ? `${lastName}` : "",
          email: senderEmail ? `${senderEmail}` : "",
          location: {
            alias: senderName ? `${senderName}` : "",
            buildingNumber: senderBuildingNumber ? `${senderBuildingNumber}` : "",
            address: senderAddress ? `${senderAddress}` : "",
            postalCode: postalCode ? `${postalCode}` : "",
            latitude: Number(lat) || 0,
            longitude: Number(lng) || 0,
            isWarning: isWarning,
          },
          phone: senderPhone ? formatPhoneNumber(String(senderPhone), referenceCountry) : "",
          notes: senderNote ? `${senderNote}` : "",
          type: StopType.PICKUP,
        }
      }

      const isSenderLocationError =
        quote?.sender &&
        !quote.sender.address &&
        quote.sender?.location?.latitude === 0 &&
        quote.sender?.location?.longitude === 0
      if (isSenderLocationError) {
        throw new Error(UPLOAD_ERRORS.SENDER_INCORRECT_LOCATION)
      }

      const client = clientId
        ? enrichedClients?.find((client) => client.clientId === clientId)
        : undefined

      if (clientId && !client) {
        throw new Error(UPLOAD_ERRORS.CLIENT_NOT_FOUND)
      }

      // Recipient
      const cleanRecipient = getCleanUploadedRecipient(
        {
          name: isUndefined(recipientName) ? "" : `${recipientName}`,
          address: isUndefined(recipientAddress) ? "" : `${recipientAddress}` || "",
          postalCode: isUndefined(String(recipientPostalCode))
            ? ""
            : `${recipientPostalCode}` || "",
          phone: isUndefined(recipientPhone) ? "" : `${recipientPhone}` || "",
          buildingNumber: isUndefined(buildingNumber) ? "" : `${buildingNumber}`,
          dropOffNote: isUndefined(dropOffNote) ? "" : `${dropOffNote}`,
          country: recipientCountry,
          email: isUndefined(recipientEmail) ? "" : `${recipientEmail}`,
          latitude: Number(recipientLatitude),
          longitude: Number(recipientLongitude),
        },
        client
      )

      if (cleanRecipient) {
        let recipientGeocodedPickup = {} as AddressDetails | undefined
        // Use cache if coordinates are not valid
        if (!cleanRecipient?.latitude && !cleanRecipient?.longitude) {
          recipientGeocodedPickup = addressCache[cleanRecipient?.address]
          if (!recipientGeocodedPickup && !!cleanRecipient?.address) {
            recipientGeocodedPickup = await getAddressDetail(
              geocodeByAddress,
              cleanRecipient?.address,
              cleanRecipient?.country || referenceCountry,
              selectedPickup
                ? new google.maps.LatLng(selectedPickup?.latitude!, selectedPickup?.longitude!)
                : undefined,
              `${cleanRecipient?.postalCode}`
            )
            if (!recipientGeocodedPickup) {
              throw new Error(
                !isUndefined(recipientLatitude) || !isUndefined(recipientLongitude)
                  ? UPLOAD_ERRORS.RECIPIENT_INCORRECT_COORDINATES
                  : UPLOAD_ERRORS.RECIPIENT_INCORRECT_ADDRESS
              )
            }
            addressCache[cleanRecipient?.address] = recipientGeocodedPickup
          }
        } else {
          if (!checkLatLonValid(cleanRecipient?.latitude, cleanRecipient?.longitude)) {
            throw new Error(UPLOAD_ERRORS.RECIPIENT_INCORRECT_COORDINATES)
          }
          // Use reverse address if coordinates are valid
          if (!cleanRecipient?.address) {
            let cacheAddress =
              coordsCache[`${cleanRecipient?.latitude},${cleanRecipient?.longitude}`]
            // If the address is not in the cache, we will get it from the API
            if (!cacheAddress) {
              try {
                cacheAddress = await getReverseAddress(
                  cleanRecipient?.latitude,
                  cleanRecipient?.longitude
                )
              } catch (error) {
                throw new Error(UPLOAD_ERRORS.RECIPIENT_INCORRECT_COORDINATES)
              }
              if (cacheAddress)
                coordsCache[`${cleanRecipient?.latitude},${cleanRecipient?.longitude}`] =
                  cacheAddress
            }
            cleanRecipient.address = cacheAddress || ""
          }
          recipientGeocodedPickup = {
            lat: cleanRecipient?.latitude,
            lng: cleanRecipient?.longitude,
            postalCode: `${cleanRecipient?.postalCode}`,
          }
        }

        if (!recipientGeocodedPickup) {
          throw new Error(UPLOAD_ERRORS.RECIPIENT_INCORRECT_ADDRESS)
        }

        const formattedPriority = !!priority ? removeAccents(priority?.toLowerCase()) : ""
        const priorityKey = priorityKeys[formattedPriority] || DestinationPriority.DEFAULT

        quote.recipients = [
          {
            expectedTimeAtStop: timeAtStop ? parseInt(timeAtStop) * 60 : 0,
            cashOnDeliveryAmount:
              isCodAvailableCountry && cashOnDeliveryAmount && Number(cashOnDeliveryAmount)
                ? Number(cashOnDeliveryAmount)
                : undefined,
            ...formatUploadedRecipientsFromTemplate(
              cleanRecipient?.address && recipientGeocodedPickup
                ? recipientGeocodedPickup
                : {
                    lat: 0,
                    lng: 0,
                    postalCode: "",
                  },
              cleanRecipient?.address ?? "",
              cleanRecipient?.name,
              cleanRecipient?.email,
              cleanRecipient?.buildingNumber,
              cleanRecipient?.phone,
              cleanRecipient?.dropOffNote,
              orderReferenceId,
              (recipientCountry as CountryCode) || referenceCountry,
              recipientGeocodedPickup?.isWarning
            ),
            type:
              !isEmpty(stopType) && stopType?.includes(StopType.PICKUP)
                ? StopType.PICKUP
                : StopType.DROPOFF,
            priority: priorityKey,
            ...(client
              ? {
                  metadata: {
                    clientId,
                  } as RecipientMetadata,
                }
              : {}),
          },
        ]

        if (quote.recipients && quote?.recipients?.[0]) {
          const parsedParcel = formatUploadedParcelDetails(
            parcelValue,
            parcelDimensions,
            parcelDimensionsV2,
            parcelWeight,
            parcelLength,
            parcelHeight,
            parcelWidth,
            parcelQuantity,
            parcelDescription ? `${parcelDescription}` : "",
            barcode
          )
          quote.recipients[0].parcels = !isEmpty(parsedParcel) ? [parsedParcel] : []
        }
      } else if (!quote?.sender) {
        throw new Error(UPLOAD_ERRORS.RECIPIENT_AND_SENDER_MISSING)
      }

      // Check end location
      // Search for the end location in the store locations list
      // Search by id or by alias
      const end = locations.find(
        (location) => location.id === endLocation || location.alias === endLocation
      )
      if (end && endLocation) {
        const arriveByDate =
          !!date && date !== DeliveryExcelTypes.ASAP
            ? date
            : DateTime.now().toFormat(EU_DATE_FORMAT_UPLOAD)

        const _endLocationTime = DateTime.fromFormat(
          arriveByDate + " " + endLocationTime,
          EU_DATE_TIME_FORMAT_UPLOAD
        )
        quote.arrival = {
          contact: {
            name: end.alias || end.address,
            phone: end.phone,
            email: end.email || "",
            notes: end.notes || "",
          },
          location: {
            address: end.address,
            latitude: end.latitude,
            longitude: end.longitude,
          },
          scheduleAt: _endLocationTime.isValid ? _endLocationTime.toMillis() : 0,
          plannedArrivalAt: 0,
          finishedAt: 0,
        }
      }

      // Team and Driver
      if (!isPublic) {
        if (teamName) {
          const team = findTeamByName(typeof teamName === "number" ? `${teamName}` : teamName)
          if (team) {
            quote.teamId = team.id
          }
        }

        if (driverName) {
          const driver =
            findDriverByName(typeof driverName === "number" ? `${driverName}` : driverName) ||
            findDriverById(typeof driverName === "number" ? `${driverName}` : driverName)
          if (driver) {
            quote.driverId = driver.id
            if (!driver.teamIDs?.includes(quote?.teamId || 0)) {
              quote.teamId = undefined
            }
            if (!isEmpty(driver?.teamIDs) && !quote.teamId) {
              quote.teamId = driver?.teamIDs?.[0]
            }
          }
        }
      }

      // Delivery Type and schedule
      const { schedule } = getSchedule(date, scheduledTime)

      if (schedule) {
        quote.schedule = schedule
      }

      // DropoffBy
      const dropoffByDate =
        !!date && date !== DeliveryExcelTypes.ASAP
          ? date
          : DateTime.now().toFormat(EU_DATE_FORMAT_UPLOAD)

      if (
        dropoffByDate &&
        (!isEmptyCell(arrivalTimeFrom) || !isEmpty(arrivalTimeTo)) &&
        quote.recipients
      ) {
        const arrivalTimeFromDT = DateTime.fromFormat(
          dropoffByDate + " " + arrivalTimeFrom,
          EU_DATE_TIME_FORMAT_UPLOAD
        )
        const arrivalTimeToDT = DateTime.fromFormat(
          dropoffByDate + " " + arrivalTimeTo,
          EU_DATE_TIME_FORMAT_UPLOAD
        )
        quote.recipients![0]!.expectedArrival = {
          from: arrivalTimeFromDT.toMillis(),
          to: arrivalTimeToDT.toMillis(),
        }
      }

      return quote
    } catch (err: any) {
      throw err
      // handleFileError(t("errors.draftUpload.convertTemplateError"), err)
      // return
    }
  }

  const convertSheetRowToOrder = async (
    order: ISheetRow,
    index: number,
    parcelRows: { order: ISheetRow; index: number }[],
    errors: UploadError[],
    onConvertRowSuccess: (order: IQuotePayload, index: number) => void
  ) => {
    if (isEmpty(order.recipientName) && isEmpty(order.clientId) && hasParcelInfo(order)) {
      parcelRows.push({ order, index })
      return
    }

    let requestOrder: IQuotePayload | undefined
    try {
      requestOrder = await convertSheetDataToQuotePayload(order)
    } catch (err: any) {
      errors.push({
        index: index + 2,
        reason: err?.message || "",
        excelRow: { ...order, index: index + 2 },
        parcels: parcelRows
          ?.filter((row) => row.order.orderReferenceId === order.orderReferenceId)
          ?.map((row) => row.order),
      })
      return
    }

    if (!requestOrder) {
      return
    }

    requestOrder.autoReassign = user?.preference?.platformReassign || false
    onConvertRowSuccess(requestOrder, index)
  }

  const handleMultiOrders = (
    requestOrders: { order: IQuotePayload; index: number }[],
    requestOrder: IQuotePayload
  ) => {
    const sameOrderIndex = requestOrders.findIndex(
      (ro) =>
        requestOrder &&
        !!requestOrder.orderNumber &&
        ro.order.orderNumber === requestOrder?.orderNumber
    )

    if (sameOrderIndex > -1 && requestOrder.recipients?.[0]) {
      requestOrders[sameOrderIndex]?.order.recipients?.push(requestOrder.recipients[0])
      return
    }
  }

  const mergeParcelsWithOrders = (
    parcelRows: { order: ISheetRow; index: number }[],
    requestOrders: { order: IQuotePayload; index: number }[]
  ) => {
    parcelRows.forEach((parcelRow) => {
      const sameStopParcel = requestOrders.findIndex(
        (ro) =>
          ro.order.recipients?.[0]?.orderReferenceId?.toString() ===
          parcelRow.order.orderReferenceId?.toString()
      )
      const parsedParcel = formatUploadedParcelDetails(
        parcelRow.order.parcelValue,
        parcelRow.order.parcelDimensions,
        parcelRow.order.parcelDimensionsV2,
        parcelRow.order.parcelWeight,
        parcelRow.order.parcelLength,
        parcelRow.order.parcelHeight,
        parcelRow.order.parcelWidth,
        parcelRow.order.parcelQuantity,
        parcelRow.order.parcelDescription ? `${parcelRow.order.parcelDescription}` : "",
        parcelRow.order.barcode
      )
      if (requestOrders[sameStopParcel] && parsedParcel) {
        requestOrders[sameStopParcel]?.order?.recipients?.[0]?.parcels?.push(parsedParcel)
      }
    })
  }

  const processTemplateFile = async (
    uploadCallback: (orders: DraftOrder[] | IQuotePayload[]) => Promise<any>
  ) => {
    const errors = [] as UploadError[]
    try {
      setIsLoading(true)
      setProgressPercent(0)
      const { parsedRows, version, workbook } = await convertFiletoJson({ file, t })

      // Filters out records having less than 2 fields set or no clientID or not rows with parcel info
      const submittedOrders = parsedRows?.filter(
        (row, index) =>
          (Object.keys(row)?.length >= 2 || !!row?.clientId || hasParcelInfo(row)) &&
          row?.__rowNum__ - index === 1
      )

      if (!submittedOrders || submittedOrders?.length === 0) {
        throw new Error(t("errors.draftUpload.noOrdersFound"))
      }

      // We use a tuple with index to preserve original order
      const requestOrders = [] as { order: IQuotePayload; index: number }[]
      const parcelRows = [] as { order: ISheetRow; index: number }[]

      // Callback post processing order
      const onConvertRowSuccess = (order: IQuotePayload, index: number) => {
        // Returns true if multiple orders and skip pushing to requestOrders
        handleMultiOrders(requestOrders, order)
        order.autoReassign = user?.preference?.platformReassign || false
        requestOrders.push({ order, index })
        setProgressPercent(
          Math.round(((requestOrders.length + errors.length) / submittedOrders.length) * 100) - 1
        )
      }

      const hasOrdersWithClients = submittedOrders?.some((order) => !!order?.clientId)
      if (hasOrdersWithClients && isEmpty(enrichedClients)) {
        await fetchClients()
      }
      // Google Geocoding API limit is 50 requests per second
      // Potentially we need to move this to the backend
      const batchSize = Math.min(submittedOrders.length, 45)
      for (let i = 0; i < submittedOrders.length; i += batchSize) {
        await Promise.all(
          submittedOrders
            .slice(i, i + batchSize)
            .map((order, index) =>
              convertSheetRowToOrder(order, i + index, parcelRows, errors, onConvertRowSuccess)
            )
        )
        await new Promise((resolve) => setTimeout(resolve, 1000))
      }

      // Add the parcel rows to the requestOrders
      mergeParcelsWithOrders(parcelRows, requestOrders)
      // Sort the requestOrders array back to its initial order
      requestOrders.sort((a, b) => a.index - b.index)
      const sortedOrders = requestOrders.map((item) => ({ ...item.order, index: item.index }))

      if (!isPublic) {
        const { data } = await createDraftOrders(sortedOrders)
        if (uploadCallback) {
          await uploadCallback(data)
        }
      } else {
        await uploadCallback(sortedOrders)
      }

      if (!isPublic && errors?.length > 0) {
        setUploadSummary({
          errors: errors,
          totalSuccess: sortedOrders?.length,
          version: version,
          workbook: workbook,
        })
      }

      setFile([])
      setSelectedPickup(undefined)
      setIsLoading(false)
      return sortedOrders
    } catch (err: any) {
      setIsLoading(false)
      setFile([])
      if (err?.response?.data?.key === "draft_bulk_order_failed" && !!err?.response?.data?.index) {
        handleFileError(t("errors.draftUpload.invalidOrder"), {
          message: t("errors.draftUpload.ordersContainsIncorrectData", {
            index: err?.response?.data?.index + 1,
          }),
          duration: true,
        })
      } else {
        handleFileError(t("errors.draftUpload.processTemplate"), err)
      }
      throw err
    }
  }

  return {
    file,
    processTemplateFile,
    isLoading,
    progressPercent,
    setFile,
    isDisabled,
    setSelectedPickup,
    selectedPickupId: selectedPickup?.id,
  }
}
